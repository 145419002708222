const LogoColored = () => {
  return (
    <svg width="132" height="38" viewBox="0 0 132 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Fluent Cargo</title>
      <path d="M26.453 12.162L26.6944 10.7939H13.8135L14.196 8.625H38.7842L38.1603 12.162" fill="#0795FF" />
      <path d="M19.1852 18.4901L19.4265 17.1225H6.93457L7.31667 14.9531H31.5633L30.9394 18.4901" fill="#0795FF" />
      <path d="M19.8054 24.8186L20.0766 23.2792H10.8086L11.1608 21.2812H29.8214L29.1975 24.8186" fill="#0795FF" />
      <path d="M13.1306 30.517L13.4018 28.9784H9.60645L9.95869 26.9805H18.0164L17.3925 30.517" fill="#0795FF" />
      <path
        d="M35.1249 13.2583L35.1462 13.3248C35.7859 15.1453 36.11 17.0558 36.11 18.9991C36.11 28.4343 28.4348 36.1113 18.9996 36.1113C9.56439 36.1113 1.88832 28.436 1.88832 19.0009C1.88832 9.56567 9.56439 1.88576 18.9996 1.88576C23.8462 1.88576 28.4437 4.08282 31.689 7.52384H34.1509L33.9389 7.25773C30.3086 2.64441 24.8637 0 18.9996 0C8.52343 0 0 8.52386 0 19C0 29.4766 8.52343 38 18.9996 38C29.4761 38 37.9987 29.4732 37.9987 18.997C37.9987 17.0413 37.6989 15.0937 37.1134 13.2583"
        fill="#003968"
      />
      <path d="M63.1647 11.7812H66.2879L63.2444 23.1303H70.3228L69.5621 25.9679H59.3604" fill="#003968" />
      <path
        d="M72.8885 19.9193L75.0672 11.7891H78.1972L76.0403 19.8381C75.4198 22.1553 76.257 23.3545 78.1677 23.3545C80.0781 23.3545 81.5474 22.1959 82.1517 19.9398L84.336 11.7891H87.466L85.3146 19.8176C84.1599 24.1268 81.1747 26.2406 77.3536 26.2406C73.5321 26.2406 71.7666 24.1063 72.8885 19.9193Z"
        fill="#003968"
      />
      <path
        d="M104.28 11.7812H107.164L111.48 20.516L113.826 11.7812H116.914L113.104 25.9679H110.443L105.979 16.9489L103.557 25.9679H100.47"
        fill="#003968"
      />
      <path d="M49.8049 11.7812L49.0427 14.6831H59.8878L60.65 11.7812H49.8049Z" fill="#0795FF" />
      <path d="M48.1933 17.5859L47.4312 20.4878H58.2758L59.0384 17.5859H48.1933Z" fill="#0795FF" />
      <path d="M50.0103 22.7422H46.8554L46.5975 23.6934L45.98 25.9664H49.1348" fill="#003968" />
      <path d="M93.0296 14.6831H100.675L101.437 11.7812H90.5919L89.8297 14.6831" fill="#0795FF" />
      <path d="M122.029 14.6398H130.339L131.1 11.8008H119.311L118.55 14.6398" fill="#0795FF" />
      <path d="M121.701 18.9218L119.815 25.9678H122.944L125.619 15.9731L122.49 15.9727" fill="#003968" />
      <path d="M91.5344 20.3237H99.0734L99.825 17.4219H89.1305L88.3789 20.3237" fill="#0795FF" />
      <path d="M89.9674 25.9672H97.6122L98.3744 23.2266H87.5297L86.7671 25.9672" fill="#0795FF" />
      <path
        d="M79.3467 33.0635H80.582C80.5869 33.0146 80.5918 32.9707 80.5918 32.9219C80.5918 31.54 79.3955 30.5 77.8135 30.5C75.6064 30.5 74 32.375 74 34.9727C74 36.7402 75.1328 37.8975 76.8516 37.8975C78.4922 37.8975 79.7275 37.0088 80.0547 35.6074H78.79C78.5508 36.3057 77.8232 36.7695 76.9688 36.7695C75.9385 36.7695 75.2939 36.042 75.2939 34.875C75.2939 33.0342 76.3535 31.6279 77.7305 31.6279C78.6631 31.6279 79.2979 32.1797 79.3467 33.0195C79.3467 33.0488 79.3467 33.0537 79.3467 33.0635Z"
        fill="#003968"
      />
      <path
        d="M85.5234 37.7217H86.7979L85.8164 30.6758H84.4346L80.3867 37.7217H81.71L82.6963 35.9199H85.3086L85.5234 37.7217ZM84.7959 32.0283H84.8789L85.2305 34.9238H83.2041L84.7959 32.0283Z"
        fill="#003968"
      />
      <path
        d="M88.0234 37.7217H89.2979L89.8594 35.0801H91.3145L92.1836 37.7217H93.541L92.5596 34.9238C93.6582 34.6016 94.3516 33.6836 94.3516 32.624C94.3516 31.4668 93.4824 30.6758 92.1836 30.6758H89.5225L88.0234 37.7217ZM90.5771 31.7207H91.959C92.6475 31.7207 93.0625 32.1016 93.0625 32.7363C93.0625 33.5225 92.4326 34.0791 91.5049 34.0791H90.0693L90.5771 31.7207Z"
        fill="#003968"
      />
      <path
        d="M101.7 34.0547H98.79L98.585 35.0361H100.25L100.211 35.2119C100.021 36.1494 99.2393 36.7646 98.248 36.7646C97.1689 36.7646 96.5 36.0322 96.5 34.8408C96.5 33.0049 97.5449 31.6328 98.9268 31.6328C99.8545 31.6328 100.504 32.1504 100.553 32.9268H101.793C101.793 32.8486 101.793 32.7998 101.793 32.7559C101.729 31.4668 100.538 30.5 99.0146 30.5C96.8027 30.5 95.2061 32.3506 95.2061 34.9385C95.2061 36.7207 96.373 37.8975 98.1162 37.8975C99.9277 37.8975 101.124 36.877 101.51 34.9727L101.7 34.0547Z"
        fill="#003968"
      />
      <path
        d="M105.763 37.8975C107.95 37.8975 109.591 36.0615 109.591 33.4639C109.591 31.7646 108.414 30.5 106.622 30.5C104.43 30.5 102.784 32.3506 102.784 34.9336C102.784 36.6328 103.985 37.8975 105.763 37.8975ZM106.563 31.6377C107.594 31.6377 108.287 32.4092 108.287 33.5664C108.287 35.3975 107.228 36.7646 105.821 36.7646C104.781 36.7646 104.083 35.9932 104.083 34.8359C104.083 33.0293 105.162 31.6377 106.563 31.6377Z"
        fill="#003968"
      />
    </svg>
  );
};

export { LogoColored };
