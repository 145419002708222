/**
 * We're using the svg's directly in the component to avoid the time delay
 * when injecting the svg into the DOM using react-svg as this is
 * important to have a super fast loading time.
 */

import styled from 'styled-components';
import { ColorProps, LayoutProps, SpaceProps } from 'styled-system';

import { Box } from '@/components/box';
import { media } from '@/utils/media';

import { LogoColored } from './colored';
import { LogoMono } from './mono';

interface LogoProps extends SpaceProps, ColorProps, LayoutProps {
  readonly variant?: 'light' | 'dark' | 'colored';
}

const Logo = ({ variant = 'light', ...props }: LogoProps) => {
  return (
    <LogoBox {...props}>
      {(variant === 'light' || variant === 'dark') && <LogoMono variant={variant} />}
      {variant === 'colored' && <LogoColored />}
    </LogoBox>
  );
};

const LogoBox = styled(Box)`
  position: relative;

  svg {
    width: 140px;
    height: 100%;

    ${media.lg} {
      width: 152px;
    }
  }
`;

export { Logo };
