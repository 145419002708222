import { isEmpty } from 'lodash';

import { AIRPORT, MODE_AIR, MODE_RAIL, MODE_ROAD, MODE_WATER, SEAPORT } from '@/lib/constants';
import { LocationInterface, RouteGroupLayout, RoutesLayout, SearchQueryInterface } from '@/types';

const defaultGroupAir: RouteGroupLayout = { groupKey: MODE_AIR, mode: [MODE_AIR], title: 'Air Routes', open: true };
const defaultGroupWater: RouteGroupLayout = {
  groupKey: MODE_WATER,
  mode: [MODE_WATER],
  title: 'Ocean Routes',
  open: true,
};
const defaultGroupLand: RouteGroupLayout = {
  groupKey: 'land',
  mode: [MODE_ROAD, MODE_RAIL],
  title: 'Land Routes',
  open: true,
};

export const DEFAULT_ROUTES_LAYOUT: RoutesLayout = {
  order: [defaultGroupAir, defaultGroupWater, defaultGroupLand],
};

export const buildRoutesLayout = ({
  origin,
  destination,
  searchParams = {},
}: {
  origin?: LocationInterface;
  destination?: LocationInterface;
  searchParams?: SearchQueryInterface;
}): RoutesLayout => {
  const groupAir = { ...defaultGroupAir };
  const groupWater = { ...defaultGroupWater };
  const groupLand = { ...defaultGroupLand };

  const { airCarriers, oceanCarriers, aircraft, aircraftType } = searchParams;

  // Predetermine filter states
  const fromToAirport = origin?.type === AIRPORT || destination?.type === AIRPORT;
  const fromToSeaport = origin?.type === SEAPORT || destination?.type === SEAPORT;
  const isSameCountry = origin?.countryCode === destination?.countryCode;
  const hasAirFilter = !isEmpty(aircraft) || !isEmpty(aircraftType) || !isEmpty(airCarriers);
  const hasOceanFilter = !isEmpty(oceanCarriers);

  // Show only OCEAN results by default if an ocean filter is applied
  if (hasOceanFilter && !hasAirFilter) {
    groupAir.open = false;
    groupLand.open = false;
  }

  // Show only air results by default an air filter is applied
  if (hasAirFilter && !hasOceanFilter) {
    groupWater.open = false;
    groupLand.open = false;
  }

  // Hide land results if air and ocean filters applied
  if (hasAirFilter && hasOceanFilter) {
    groupLand.open = false;
  }

  // If Seaport orign or dest, put ocean results first, else air first
  const defaultOrder = fromToSeaport ? [groupWater, groupAir] : [groupAir, groupWater];
  // Now sort by visibility
  const order = defaultOrder.sort((a, b) => Number(b.open) - Number(a.open));

  // Determine if we put road results at top or bottom
  if (isSameCountry && !hasAirFilter && !hasOceanFilter && !fromToAirport && !fromToSeaport) {
    // Put road first, if same country and no mode filters or port rules applied
    order.unshift(groupLand);
  } else {
    // Put road last in all other cases
    order.push(groupLand);
  }

  return {
    order,
  };
};
