/**
 * Fluent API provider
 *
 * This file contains all the functions that are used to interact with our API.
 * @NOTE: Some queries are "missing" as we query them with SWR instead which
 * allows us to cache the data and keep it up to date better than trying
 * to do it manually with Recoil.
 *
 * e.g. getOrgUsers, getOrgInvites - and we may add more to SWR in the future.
 * You can find these in src/utils/helpers/urls/index.ts
 */
import { omit } from 'lodash';

import { FeedbackFormInterface } from '@/components/user-nav/feedback-popover';
import { RouteSummaryModel } from '@/lib/models/route-summary/types';
import {
  PortInterface,
  PortsUrlQueryParams,
  QuoteFormInputsInterface,
  QuotesInterface,
  SubscriptionPlan,
  SubscriptionPrice,
  TriggerCrmEventInterface,
} from '@/types';
import { CarriersDataApiInterface, PortDataApiInterface, PortsDataApiInterface } from '@/types/api-types';
import {
  OrgsPublicTableInterface,
  OrgsUsersPublicTableInterface,
  OrgsWithUsageInterface,
  UsersPublicTableInterface,
} from '@/types/database-types';
import { request } from '@/utils/api/request';
import { Fluent } from '@/utils/environment';
import { getAllCarriersUrl, getPlansUrl, getPortsUrl } from '@/utils/helpers/api-urls';

const { FLUENT_API_URL } = Fluent.env;

interface ReturnInterface {
  error?: string;
}

interface CreateCheckoutReturnInterface {
  stripeSessionId: string;
}

interface CreatePortalReturnInterface {
  url: string;
}

interface SubscriptionPlanReturnInterface {
  plans: SubscriptionPlan[];
}

interface AddedQuoteReturnInterface {
  error?: string;
  id: string;
}

interface QuoteForwardingInterface extends Omit<QuotesInterface, 'status'> {
  partnerName: string;
  partnerEmail: string;
}

interface FeedbackInterface extends FeedbackFormInterface {
  meta: {
    url: string;
    postHogUserId: string;
  };
}

const fluentProvider = {
  deleteUser: async (): Promise<ReturnInterface> => {
    return request(`${FLUENT_API_URL}/auth/delete-user`);
  },

  updateUser: async (user: UsersPublicTableInterface): Promise<ReturnInterface> => {
    const config = {
      body: {
        // Remove certain fields that we don't want in the DB (and will cause issues
        // in Supabase trying to add data to columns that don't exist)
        user: omit(user, [
          'accountType',
          'confirmPassword',
          'emailVerified',
          'hasPassword',
          'passwordStrengthScore',
          'showPassword',
          'isNewUser',
          'orgs',
        ]),
      },
    };

    return request(`${FLUENT_API_URL}/auth/update-user`, config);
  },

  /**
   * Get an organisations details with usage data
   * @param orgId - The name of the organisation
   */
  getOrgWithUsage: async (orgId: OrgsPublicTableInterface['id']): Promise<OrgsWithUsageInterface> => {
    return request(`${FLUENT_API_URL}/orgs/${orgId}/usage`, undefined, 'GET');
  },

  /**
   * Update an organisation
   * @param orgId - The id of the organisation to update
   * @param org - Object containing updated org data
   */
  updateOrg: async (orgId: string, org: OrgsPublicTableInterface): Promise<ReturnInterface> => {
    const config = {
      body: { ...org },
    };
    return request(`${FLUENT_API_URL}/orgs/${orgId}`, config, 'PUT');
  },

  /**
   * Delete an organisation
   * @param orgId - The id of the organisation
   */
  deleteOrg: async (orgId: OrgsPublicTableInterface['id']): Promise<ReturnInterface> => {
    return request(`${FLUENT_API_URL}/orgs/${orgId}`, undefined, 'DELETE');
  },

  /**
   * Update a user's role in an organisation
   * @param userId - The id of the user
   * @param orgId - The id of the organisation
   * @param userRole - The role of the user
   */
  updateOrgUser: async (
    orgId: OrgsPublicTableInterface['id'],
    userId: OrgsUsersPublicTableInterface['userId'],
    userRole: OrgsUsersPublicTableInterface['userRole'],
  ): Promise<ReturnInterface> => {
    const config = {
      body: { userRole },
    };

    return request(`${FLUENT_API_URL}/orgs/${orgId}/users/${userId}`, config, 'PUT');
  },

  /**
   * (Soft) Delete a user from an organisation
   * @param userId - The id of the user
   * @param orgId - The id of the organisation
   */
  deleteOrgUser: async (
    orgId: OrgsPublicTableInterface['id'],
    userId: OrgsUsersPublicTableInterface['userId'],
  ): Promise<ReturnInterface> => {
    return request(`${FLUENT_API_URL}/orgs/${orgId}/users/${userId}`, undefined, 'DELETE');
  },

  /**
   * Creates a Stripe Checkout Session for a user
   * @param price - The Id of the selected Price Obj
   */
  createStripeCheckoutLink: async (price: SubscriptionPrice): Promise<CreateCheckoutReturnInterface> => {
    const config = {
      body: { priceId: price.id },
    };
    return request(`${FLUENT_API_URL}/subscription/checkout`, config, 'POST');
  },

  /**
   * Creates a Stripe Portal Session for a user
   */
  createStripePortalLink: async (): Promise<CreatePortalReturnInterface> => {
    const config = {
      body: {},
    };
    return request(`${FLUENT_API_URL}/subscription/portal`, config, 'POST');
  },

  /**
   * Gets active Stripe Plans from DB
   */
  getSubscriptionPlans: async (): Promise<SubscriptionPlanReturnInterface> => {
    return request(getPlansUrl());
  },

  /**
   * Adds a new quote
   */
  addQuote: async (data: QuoteFormInputsInterface): Promise<AddedQuoteReturnInterface> => {
    const config = {
      body: { ...data },
    };

    return request(`${FLUENT_API_URL}/quotes/`, config);
  },

  /**
   * Updates a quote
   */
  updateQuote: async (data: QuotesInterface): Promise<ReturnInterface> => {
    const config = {
      body: { ...data },
    };

    return request(`${FLUENT_API_URL}/quotes/${data.id}`, config, 'PUT');
  },

  /**
   * Sends the quote emails to the user and admin
   */
  sendQuoteEmails: async (data: QuoteFormInputsInterface): Promise<ReturnInterface> => {
    const config = {
      body: { ...data },
    };

    return request(`${FLUENT_API_URL}/quotes/email`, config);
  },

  /**
   * Sends a rejection email to the user
   */
  sendQuoteRejectionEmail: async ({ email, id }: { email: string; id: string }): Promise<ReturnInterface> => {
    const config = {
      body: { email, id },
    };

    return request(`${FLUENT_API_URL}/quotes/email`, config, 'DELETE');
  },

  /**
   * Forwards the details to a partner
   */
  sendQuoteForwardingEmail: async (data: QuoteForwardingInterface): Promise<ReturnInterface> => {
    const config = {
      body: { ...data },
    };

    return request(`${FLUENT_API_URL}/quotes/email`, config, 'PATCH');
  },

  /**
   * Sends feedback to the admin
   */
  sendFeedbackEmail: async ({ feedback, email, meta }: FeedbackInterface): Promise<ReturnInterface> => {
    const config = {
      body: { feedback, email, meta },
    };

    return request(`${FLUENT_API_URL}/feedback`, config, 'POST');
  },

  /**
   * Triggers an event in Mailchimp (Which typically triggers a customer journey)
   */
  triggerCrmEvent: async ({ email, eventName }: TriggerCrmEventInterface): Promise<ReturnInterface> => {
    const config = {
      body: {
        email,
        eventName,
      },
    };

    return request(`${FLUENT_API_URL}/crm`, config, 'POST');
  },

  /**
   * Gets all Ports
   */
  getPorts: async (params?: PortsUrlQueryParams): Promise<PortsDataApiInterface> => {
    return request(getPortsUrl(params));
  },

  /**
   * Gets a single Port
   */
  getPortById: async (id: PortInterface['id']): Promise<PortDataApiInterface> => {
    return request(`${getPortsUrl()}/${id}`);
  },

  /**
   * Gets a route summary for a given origin and destination
   *
   * @param pol - The Port of Loading
   * @param pod - The Port of Discharge
   */
  getRouteSummary: async ({ pol, pod }: { pol: string; pod: string }): Promise<RouteSummaryModel> => {
    return request(`${FLUENT_API_URL}/route-summary?&pol=${pol}&pod=${pod}`);
  },

  /**
   * Gets a stripped down list of carriers
   */
  getAllCarriers: async (): Promise<CarriersDataApiInterface> => {
    return request(getAllCarriersUrl());
  },
};

export { fluentProvider };
