import { useRecoilState, useSetRecoilState } from 'recoil';

import { navIsOpenState } from '@/state/navigation';
import { filtersPanelOpenState } from '@/state/routes';
import { tv } from '@/utils/styles';

export interface NavToggleProps {
  variant?: 'light' | 'dark';
  top?: string;
  className?: string;
}

const NavToggle = ({ variant, className, ...props }: NavToggleProps) => {
  const [navIsOpen, setNavIsOpen] = useRecoilState(navIsOpenState);
  const setFiltersAreOpen = useSetRecoilState(filtersPanelOpenState);

  const toggleNav = () => {
    setNavIsOpen(!navIsOpen);
    setFiltersAreOpen(false);
  };

  const { base, bar1, bar2, bar3 } = styles({ variant, navIsOpen });

  return (
    <button onClick={() => toggleNav()} className={base({ className })} type="button" {...props}>
      <span className={bar1()} />
      <span className={bar2()} />
      <span className={bar3()} />
    </button>
  );
};

const commonBarStyles = 'absolute mx-auto my-[7px] block h-[3px] w-[1.75rem] rounded-[2px] bg-blue-700';
const commonBarStylesNavIsOpen =
  'top-4 transform bg-blue-700 [transition:_top_200ms_ease_100ms,transform_200ms_ease-out_400ms]';
const topAndBottomBarTransition =
  '[transition:_top_200ms_ease,transform_200ms_ease-out,background-color_300ms_ease-in-out]';

const styles = tv({
  slots: {
    base: 'absolute right-4 top-[15px] m-auto h-12 w-12 cursor-pointer pl-2 pt-0 md:hidden',
    bar1: `${commonBarStyles} ${topAndBottomBarTransition} top-2 animate-rotate-left`,
    bar2: `${commonBarStyles} top-4 opacity-100 [transition:_opacity_200ms_ease,background-color_300ms_ease-in-out]`,
    bar3: `${commonBarStyles} ${topAndBottomBarTransition} top-6 animate-rotate-right`,
  },
  variants: {
    variant: {
      light: {
        bar1: 'bg-white',
        bar2: 'bg-white',
        bar3: 'bg-white',
      },
      dark: null,
    },
    navIsOpen: {
      true: {
        bar1: `${commonBarStylesNavIsOpen} rotate-45`,
        bar2: 'bg-blue-700 opacity-0',
        bar3: `${commonBarStylesNavIsOpen} -rotate-45`,
      },
    },
  },
});

export { NavToggle };
